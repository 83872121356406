<script>
    import Merchandising from "@/services/Merchandising";
    import Swal from "sweetalert2";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';


    export default {
        components: {VueBootstrapTypeahead},

        props: {
            product_id: String
        },
        data() {
            return {
                productRelated: [],
                showLoader2: false,
                suggestionsPromo:[],
                showLoader3: false,
                showLoader4: false ,
                csrf_token: localStorage.getItem('csrf_token'),
                searchPromo: '',
                suggestionsTopSale:[],
                resetKeyProductId: 0,
                resetKeyProductName: 0,
                filterInput: {
                    product_id_related: '',
                    product_name: '',
                },
                hover: false
            };
        },
        watch: {
            product_id: {
                immediate: true,
                handler(newProductId) {
                    this.getProductRelated(newProductId);
                }
            }
        },
        async created() {

            var params_promo = 'on_promotion=0';
            await this.getProductsAutocomplete(params_promo, 'promo');

            var params_top_sale = 'top_sale=0';
            this.getProductsAutocomplete(params_top_sale, 'top_sale');

            this.getFilters();


        },
        methods: {
            async getProductRelated(productId) {
                try {
                    const response = await Merchandising.getProductRelated(productId);
                    if (response && response.data.data.length > 0) {
                        this.productRelated = response.data.data.map(product => ({
                            ...product,
                            hover: false
                        }));
                    } else {
                        this.productRelated = [];
                    }
                } catch (error) {
                    console.error("Error fetching related products:", error);
                }
            },
            async getProductsAutocomplete(params, type){
                try {
                    this.toggleBusy();
                    const response = await Merchandising.getProductsAutocomplete(params)

                    var prep = [];
                    response.data.data.forEach(function (item) {
                        var product_name = item.id+' '+item.name
                        prep.push(product_name);
                    });

                    if(type == 'promo'){
                        this.suggestionsPromo = prep;
                    } else if(type == 'top_sale'){
                        this.suggestionsTopSale = prep;
                    }
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.errorTop = error.response.data.error ? error.response.data.error : "";

                }
            },
            getFilters() {
                var filter_string = '&limit=1000';
                filter_string += (this.filterInput.product_id) ? '&id=' + this.filterInput.product_id : "";
                filter_string += (this.filterInput.product_name) ? '&name=' + this.filterInput.product_name : "";
                return filter_string = filter_string.substring(1);
            },
            async getProducts() {
                try {
                    this.filterData = true;
                    this.toggleBusy();
                    let filters = this.getFilters()
                    const response = await Merchandising.getProducts(filters)

                    this.toggleBusy();
                }
                catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },
            addRelatedProduct(){

                var rel_slug = this.searchPromo.substring(0, this.searchPromo.indexOf(' '));
                this.searchPromo='';
                this.resetKeyProductId += 1;
                this.showLoader3 = true;
                try {
                    Merchandising.addRelatedProduct(this.product_id, rel_slug)
                        .then((response) => {
                            this.showLoader3 = false
                            const res = response.data ? response.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            if(res){
                                Swal.fire("Add Related Product", "Related Product is successfully added!", "success");
                                this.getProductRelated(this.product_id);
                            }else{
                                this.showLoader3 = false;
                                Swal.fire("Fail!", error, "warning");

                            }
                        })
                }catch(error){
                    this.showLoader3 = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },
            removeRelated(rel_slug){
                 Swal.fire({
                    title: "Remove related product?",
                    text: "This product will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Merchandising.removeRelated(this.product_id,rel_slug)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getProductRelated(this.product_id);
                                        Swal.fire("Remove related product", "Related product is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },
        }
    };
</script>
<template>
    <b-form-group class="text-primary text-center">
        <div class="row">
            <h5 class="text-center">Related Products</h5>

            <template v-if="!showLoader2">
                <v-app class="sponsors-app">
                    <v-sheet>
                        <v-slide-group show-arrows>
                            <v-row>
                                <!-- Display related products -->
                                <v-col v-for="product in productRelated" :key="product.Art_ID">
                                    <b-card no-body class="product-card" @mouseover="product.hover = true" @mouseleave="product.hover = false">
                                        <b-card-body>
                                            <b-card-title class="text-center text-truncate" style="min-height: 108px;">
                                                <router-link :to="{ path: `/merchandising/product_details/${product.Art_ID}`}" class="text-decoration-none" style="color: inherit; text-decoration: none;" :class="{ 'hover-color': hover }">
                                                    <p class="card-title" style="white-space: normal; word-wrap: break-word; margin-bottom: 0.5rem;" :style="{ 'color': hover ? '#007bff' : 'inherit' }"> <strong>{{ product.Art_Ime ? product.Art_Ime : product.Art_ImeKratko }}</strong></p>
                                                </router-link>
                                                <h6 class="text-muted" style="margin-top: 0.5rem;">
                                                    Regular Price: {{ product.Art_CenaP1 }}
                                                </h6>
                                                <h6 class="text-muted" style="margin-top: 0.5rem;">
                                                    Club Price: {{ product.Art_CenaP3 }}
                                                </h6>
                                            </b-card-title>
                                            <div class="product-image-grid-small text-center">
                                                <img class="img-fluid img-thumbnail" :src="product.image" />
                                            </div>
                                            <b-button variant="primary btn-danger mt-5" @click="removeRelated(product.Art_ID)" >
                                                Remove Products
                                            </b-button>

                                        </b-card-body>
                                    </b-card>
                                </v-col>
                            </v-row>
                        </v-slide-group>
                    </v-sheet>
                </v-app>

                <div v-if="productRelated.length <= 0">
                    <p class="text-center mt-3">No related products found</p>
                </div>
            </template>
            <template v-else>
                <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader2">
                    <b-spinner large></b-spinner>
                </div>
            </template>
        </div>
        <hr>
        <h5 class="mt-4">Add related products</h5>
        <form @submit.prevent="addRelatedProduct" class="mb-5">
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-9 ">
                    <vue-bootstrap-typeahead :key="resetKeyProductId" v-model="searchPromo" :data="suggestionsPromo" />
                </div>
                <div class="col-sm-2">
                    <input type="hidden" name="csrf_token" v-model="csrf_token" />
                    <b-button variant="primary" @click="addRelatedProduct" :disabled="showLoader3">
                        <b-spinner v-show="showLoader3" small></b-spinner>Add Products
                    </b-button>
                </div>
            </div>
        </form>
    </b-form-group>
</template>
