<script>
    import Merchandising from "@/services/Merchandising";
    import Swal from "sweetalert2";

    export default {
        props: {
            product_id: String
        },
        data() {
            return {
                productPromotions: null,
                tryingToEditPromo: false,
                tryingToEditTopSale: false,
                csrf_token: localStorage.getItem('csrf_token'),
                productTopSale: [] ,

            };
        },
        async created() {
            this.getPromotions();
            this.getBestSeller();
        },
        methods: {
            async getPromotions() {
                try {
                    const response = await Merchandising.getPromotions('product_id=' + this.product_id);
                    if (response && response.data.data.length > 0) {
                        this.productPromotions = response.data.data[0];
                    } else {
                        this.productPromotions = null;
                    }
                } catch (error) {
                    console.error("Error fetching product Promotions:", error);
                }
            },

            addPromotion(){
                this.tryingToEditPromo = true;
                var id = this.product_id;

                try {
                    Merchandising.addPromotion(id)
                        .then((response) => {
                            const res = response.data ? response.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            this.tryingToEditPromo = false;
                            if(res){
                                this.getPromotions()
                                Swal.fire("Add promotion", "Promotion is successfully added!", "success");
                            }else{
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                }catch(error){
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tryingToEditPromo = false;
                }
            },

            removePromotion(){
                var id = this.product_id;

                Swal.fire({
                    title: "Remove promotion?",
                    text: "This promotion will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Merchandising.removePromotion(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getPromotions()
                                        Swal.fire("Remove promotion", "Promotion is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },

            async getBestSeller(){
                try {
                    this.toggleBusy();
                    const response = await Merchandising.getBestSeller('product_id=' + this.product_id)
                    this.productTopSale = response.data.data ;
                    console.log(this.productTopSale);
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.errorTop = error.response.data.error ? error.response.data.error : "";

                }
            },
            addBestSeller(){
                this.tryingToEditTopSale = true;
                var id = this.product_id;
                try {
                    Merchandising.addBestSeller(id)
                        .then((response) => {
                            const res = response.data ? response.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            this.tryingToEditTopSale = false;
                            if(res){
                                this.getBestSeller()
                                Swal.fire("Add best seller", "Best seller is successfully added!", "success");

                            }else{
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                }catch(error){
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tryingToEditPromo = false;
                }
            },
            removeBestSeller(){
                var id = this.product_id;
                Swal.fire({
                    title: "Remove best seller?",
                    text: "This best seller will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Merchandising.removeBestSeller(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getBestSeller()
                                        Swal.fire("Remove best seller", "Best seller is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },
            async success() {
                this.getPromotions();
            },
        }
    };
</script>
<template>
    <div>
        <div v-if="productPromotions" class="d-flex align-items-center mb-2">
                    <h5 style="margin-right: 10px;">Product On Promotion</h5>
                    <i class="fa fa-check font-size-22" style="color: green;"></i>
                    <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Promotion" @click="removePromotion">
                        <i class="uil uil-trash-alt font-size-22"></i>
                    </a>
            <hr>
        </div>
        <div v-else>
            <h6>No promotion available for this product.</h6>
            <form @submit.prevent="addPromotion" class="mb-5">
                <div class="row">
                    <div>
                        <input type="hidden" name="csrf_token" v-model="csrf_token" />
                        <b-button variant="primary" @click="addPromotion" :disabled="tryingToEditPromo">
                            <b-spinner v-show="tryingToEditPromo" small></b-spinner>Add Promotion
                        </b-button>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <div>

            <div v-if="productTopSale && productTopSale.length > 0 " class="d-flex align-items-center mb-2">
                <h5 style="margin-right: 10px;">Product Best Seller</h5>
                <i class="fa fa-check font-size-22" style="color: green;"></i>
                <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Best Seller" @click="removeBestSeller()">
                    <i class="uil uil-trash-alt font-size-22"></i>
                </a>
            </div>
            <div v-else>
                <h6>No top sale for this product.</h6>
                <form @submit.prevent="addBestseller" class="mb-5">
                    <div class="row">
                        <div class="col-sm-4">
                            <input type="hidden" name="csrf_token" v-model="csrf_token" />
                            <b-button variant="primary" @click="addBestSeller" :disabled="tryingToEditTopSale">
                                <b-spinner v-show="tryingToEditTopSale" small></b-spinner>Add Best Seller
                            </b-button>
                        </div>
                    </div>
                </form>



            </div>
        </div>
    </div>
</template>






